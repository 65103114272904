:root {
    --primary: #0f3460;
    --secondary: #ff5722;
    --success: #4caf50;
    --info: #00bcd4;
    --warning: #ff9800;
    --danger: #f44336;
    --light: #f1f1f1;
    --dark: #212121;
}

.bg-primary{
    background-color: var(--primary) !important;
}

.text-white {
    color: #f1f1f1;
}