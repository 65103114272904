.card {
    box-shadow: 0 0 1px gray;
}

.card-lg {
    box-shadow: 0 0 2px rgb(128, 128, 128);
}

.divider {
    border-bottom: 1px solid rgb(182, 182, 182);
    margin: 10px 0;
}

.loader-parent {
    position: absolute;
    inset: 0;
    background-color: rgba(0,0,0, 0.63);
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loader {
    height: 80px;
    width: 80px;
    border: 5px solid white;
    border-left-color: transparent;
    border-radius: 50%;
    animation: loader 1s linear infinite;
}

.option{
    box-shadow: rgba(0,0,0, 0.638) 0px 0px 1.5px;
    padding: 10px;
    border-radius: 2px;
}

.selected-option{
    box-shadow: rgba(0,0,0, 0.638) 0px 0px 1px;
    padding: 10px;
    border-radius: 2px;
    border: 2px solid var(--primary);
}

@keyframes loader {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.result {
    background-color: #a5c8c9;
    padding: 30px;
    color: white !important;
    border-radius: 5px;
}

.lottie-animation{
    height: 300px;
}

.timer {
    background-color: #894114;
    color: white;
    padding: 1.5em;
    display: flex;
    align-items: center;
    height: 80px;
    width: 80px;
    text-align: center;
    box-sizing: border-box;
}